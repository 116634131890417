var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("chercher")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"chercher","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('b-form-group',[_c('b-button',{staticClass:"ml-1 d-inline-block",attrs:{"to":{ name: 'create-order' },"variant":"gradient-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Ajouter vente")])],1)],1),_c('b-form-group',[_c('b-button',{staticClass:"ml-1 d-inline-block",attrs:{"variant":"gradient-primary"},on:{"click":_vm.printAll}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Imprimer")])],1)],1)],1),(_vm.load === 'true')?_c('div',{staticClass:"text-center mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('vue-good-table',{ref:"my-table",attrs:{"current-page":_vm.currentPage,"columns":_vm.columns,"rows":_vm.orders,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    },"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'number')?_c('div',{staticClass:"text-nowrap"},[_c('router-link',{staticClass:"text-primary",attrs:{"to":{ name: 'show-custom-order', params: { id: props.row.id } }}},[_vm._v(" "+_vm._s(props.row.number)+" ")])],1):(props.column.field === 'status')?_c('span',[(props.row.status === 'en attente')?_c('div',[_c('b-badge',{staticClass:"badge-glow bg-danger bg-lighten-1",attrs:{"pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(" En attente")])],1)],1):_vm._e(),(props.row.status === 'au dépôt')?_c('div',[_c('b-badge',{staticClass:"badge-glow bg-success bg-darken-2",attrs:{"pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"CheckIcon"}}),_c('span',[_vm._v(" Au dépôt")])],1)],1):_vm._e(),(props.row.status === 'livré')?_c('div',[_c('b-badge',{staticClass:"badge-glow bg-success bg-lighten-1",attrs:{"pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"CheckIcon"}}),_c('span',[_vm._v(" Livré")])],1)],1):_vm._e()]):(props.column.field === 'action')?_c('span',[_c('span',[_c('router-link',{staticClass:"text-warning",attrs:{"to":{ name: 'show-custom-order', params: { id: props.row.id } }}},[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-show-icon"),"icon":"PrinterIcon","size":"16"}})],1),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Détail","target":("invoice-row-" + (props.row.id) + "-show-icon")}}),_c('router-link',{staticClass:"text-info",attrs:{"to":{
              name: 'create-exit-voucher-without-driver',
              params: { id: props.row.id },
            }}},[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-bs-icon"),"icon":"LogInIcon","size":"16"}})],1),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Bon Sortie sans chauffeur","target":("invoice-row-" + (props.row.id) + "-bs-icon")}}),_c('router-link',{staticClass:"text-primary",attrs:{"to":{
              name: 'create-exit-voucher-with-driver',
              params: { id: props.row.id },
            }}},[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-bsa-icon"),"icon":"TruckIcon","size":"16"}})],1),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Bon Sortie avec chauffeur","target":("invoice-row-" + (props.row.id) + "-bsa-icon")}}),_c('span',[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"id":("invoice-row-" + (props.row.id) + "-trash-icon"),"icon":"TrashIcon","color":"red","size":"16"},on:{"click":function($event){return _vm.deleteOrder(props.row)}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Supprimer","target":("invoice-row-" + (props.row.id) + "-trash-icon")}})],1),_c('span',[_c('feather-icon',{staticClass:"cursor-pointer m-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-preview-icon"),"icon":"FileTextIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                  name: 'facture-preview',
                  params: { id: props.row.id },
                })}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Générer facture","target":("invoice-row-" + (props.row.id) + "-preview-icon")}})],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Affichage 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['50', '25', '15']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(_vm.count)+" résultats trouvés ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.count,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function($event){return _vm.pageNext(_vm.currentPage)}},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }